import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import _ from "lodash";
import moment from "moment";
import tw from "tailwind-styled-components";
import { RotatingLines } from "react-loader-spinner";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ClubDetails } from "@containers";
import { get, set } from "@utils";
import { PaymentDetails } from "@containers";
import { payLoader, getCounty, getListingBookings } from "@store";
import {
  PrimaryText,
  TostMessage,
  TermAndConditionModal,
  PrivacyPolicyModal,
} from "@components";
import Config from "../../../config";

const stripePromise = loadStripe(Config.STRIPE_PUBLISH_KEY);

const paymentOptions = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  appearance: {
    rules: {
      ".Label": {
        color: "#ffffffb3",
      },
    },
  },
};

function CheckOutForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const location = useLocation();
  const playersPriceList = location.state;
  const userStatus = useSelector((state) => state.users.status);
  const countriesList = useSelector((state) => state.setting.county);
  const paymentLoader = useSelector((state) => state.payment.payLoader);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      email: "",
      telePhone: "",
      handicap: "",
      cdhNumber: "",
      club: "Ring of Kerry Golf",
      specialRequests: "",
      player2Name: "",
      player3Name: "",
      player4Name: "",
      player2Handicap: "",
      player3Handicap: "",
      player4Handicap: "",
      player2CDHNumber: "",
      player3CDHNumber: "",
      player4CDHNumber: "",
      player2SwitchBtn: true,
      player3SwitchBtn: true,
      player4SwitchBtn: true,
      country: { label: "Ireland" },
      county: "",
      countryList: [],
      countyList: [],
      checkBox: false,
      countryDropdown: false,
      privacyPolicyModal: false,
      termConditionModal: false,
      payClick: false,
      userData: null,
      tournamentData: null,
      torunamentId: null,
      bookingListPayload: [],
      teeTimeISBooked: false,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  const prices = playersPriceList?.length
    ? playersPriceList?.map((a) => a?.activePrice)
    : "";
  const totalPrice = playersPriceList?.length
    ? prices?.reduce((acc, curr) => acc + curr)
    : "";

  const theme = createTheme({
    palette: {
      success: {
        main: "#ffffff",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  useEffect(() => {
    let country_List = [];
    let countriesCounty;
    if (countriesList?.data?.length) {
      // countriesList?.data?.map((res) => {
      //   country_List?.push({ label: res?.countryName });
      // });
      countriesList?.data?.forEach((res) => {
        country_List?.push({ label: res?.countryName });
      });
      countriesCounty = countriesList?.data?.find((e) => {
        return e?.countryName === state.country?.label;
      });
      let countylist = [];
      if (countriesCounty?.counties?.length) {
        // countriesCounty?.counties?.map((res) => {
        //   countylist?.push({ label: res?.title });
        // });
        countriesCounty?.counties?.forEach((res) => {
          countylist?.push({ label: res?.title });
        });
        setState({ countyList: countylist, county: countylist[0] });
      } else {
        setState({ countyList: [], county: "" });
      }
    }
    setState({ countryList: country_List });
  }, [countriesList, state.country]);

  useEffect(() => {
    dispatch(getCounty());
    fillUserDetail();

    const tournamentDetails = get("CompetitonBookData");
    if (tournamentDetails !== null) {
      const data = JSON.parse(get("CompetitonBookData"));
      setState({
        tournamentData: data?.competitonBookData,
        tournamentId: data?.competitonBookData?.tournamentId,
      });

      let selectedPlayer = data?.competitonBookData?.slot?.id;
      let selectedDate = data?.competitonBookData?.teeTime?.data?.time;
      let bookingsApiData = {
        fromDate: moment(selectedDate).subtract(1, "day").format("DD-MM-YYYY"),
        toDate: moment(selectedDate).add(1, "day").format("DD-MM-YYYY"),
      };
      dispatch(getListingBookings(bookingsApiData)).then((res) => {
        if (res?.payload?.data?.data?.data && res?.payload?.status === 200) {
          let listingData = res?.payload?.data?.data?.data;

          const bookedTeeTime = _.filter(listingData, function (e) {
            return (
              e?.teetime === selectedDate &&
              e?.course === data?.competitonBookData?.teeTime?.data?.course &&
              e?.status === "BOOKED" &&
              4 - e?.players < selectedPlayer
            );
          });
          if (bookedTeeTime?.length) {
            setState({ teeTimeISBooked: true });
          } else {
            setState({ teeTimeISBooked: false });
          }
        } else {
          setState({ teeTimeISBooked: false });
        }
      });
    }
  }, [dispatch]);

  const fillUserDetail = () => {
    const localUsers = localStorage.getItem("localUserData");
    if (localUsers !== null) {
      let data = JSON.parse(localUsers);
      setState({
        email: data?.email,
        handicap: data?.handicap,
        lastName: data?.lastName,
        checkBox: data?.checkBox,
        cdhNumber: data?.cdhNumber,
        firstName: data?.firstName,
        telePhone: data?.telePhone,
        player2Name: data?.player2Name,
        player3Name: data?.player3Name,
        player4Name: data?.player4Name,
        player2Handicap: data?.player2Handicap,
        player3Handicap: data?.player3Handicap,
        player4Handicap: data?.player4Handicap,
        specialRequests: data?.specialRequests,
        player2CDHNumber: data?.player2CDHNumber,
        player3CDHNumber: data?.player3CDHNumber,
        player4CDHNumber: data?.player4CDHNumber,
      });
    }
  };

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users !== null) {
      const user_Data = JSON.parse(users);
      setState({
        firstName: user_Data?.user?.firstName,
        lastName: user_Data?.user?.lastName,
        email: user_Data?.user?.email,
        telePhone: user_Data?.user?.phone,
      });
    }
  }, [userStatus]);

  useEffect(() => {
    if (state.player2SwitchBtn) {
      setState({
        player2Name: state.lastName
          ? `${state.firstName} ${state.lastName} Guest1`
          : `${state.firstName} Guest1`,
      });
    } else {
      setState({
        player2Name: "",
      });
    }
  }, [state.player2SwitchBtn, state.firstName, state.lastName]);

  useEffect(() => {
    if (state.player3SwitchBtn) {
      setState({
        player3Name: state.lastName
          ? `${state.firstName} ${state.lastName} Guest2`
          : `${state.firstName} Guest2`,
      });
    } else {
      setState({
        player3Name: "",
      });
    }
  }, [state.player3SwitchBtn, state.firstName, state.lastName]);

  useEffect(() => {
    if (state.player4SwitchBtn) {
      setState({
        player4Name: state.lastName
          ? `${state.firstName} ${state.lastName} Guest3`
          : `${state.firstName} Guest3`,
      });
    } else {
      setState({
        player4Name: "",
      });
    }
  }, [state.player4SwitchBtn, state.firstName, state.lastName]);

  useEffect(() => {
    if (playersPriceList?.length) {
      let player1Detail = {
        contactFirstName: state.firstName,
        contactLastName: state.lastName,
        teeId: state.tournamentData?.teeTime?.data?.id,
        course: state.tournamentData?.data?.courseId?.courseName,
        players: 1,
        teetime: moment(state.tournamentData?.teeTime?.data?.time).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        holes: state.tournamentData?.teeTime?.data?.holes,
        netRate: playersPriceList[0]?.activePrice,
        playerType: playersPriceList[0]?.playerTypeId,
      };
      let player2Detail = {
        contactFirstName: state.player2Name,
        contactLastName: "",
        teeId: state.tournamentData?.teeTime?.data?.id,
        course: state.tournamentData?.data?.courseId?.courseName,
        players: 1,
        teetime: moment(state.tournamentData?.teeTime?.data?.time).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        holes: state.tournamentData?.teeTime?.data?.holes,
        netRate: playersPriceList[1]?.activePrice,
        playerType: playersPriceList[1]?.playerTypeId,
      };
      let player3Detail = {
        contactFirstName: state.player3Name,
        contactLastName: "",
        teeId: state.tournamentData?.teeTime?.data?.id,
        course: state.tournamentData?.data?.courseId?.courseName,
        players: 1,
        teetime: moment(state.tournamentData?.teeTime?.data?.time).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        holes: state.tournamentData?.teeTime?.data?.holes,
        netRate: playersPriceList[2]?.activePrice,
        playerType: playersPriceList[2]?.playerTypeId,
      };
      let player4Detail = {
        contactFirstName: state.player4Name,
        contactLastName: "",
        teeId: state.tournamentData?.teeTime?.data?.id,
        course: state.tournamentData?.data?.courseId?.courseName,
        players: 1,
        teetime: moment(state.tournamentData?.teeTime?.data?.time).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        holes: state.tournamentData?.teeTime?.data?.holes,
        netRate: playersPriceList[3]?.activePrice,
        playerType: playersPriceList[3]?.playerTypeId,
      };
      let playerArr = [];

      if (state.tournamentData?.slot?.id === 1) {
        playerArr = [player1Detail];
      } else if (state.tournamentData?.slot?.id === 2) {
        playerArr = [player1Detail, player2Detail];
      } else if (state.tournamentData?.slot?.id === 3) {
        playerArr = [player1Detail, player2Detail, player3Detail];
      } else {
        playerArr = [
          player1Detail,
          player2Detail,
          player3Detail,
          player4Detail,
        ];
      }

      setState({ bookingListPayload: playerArr });
    } else {
    }
  }, [
    playersPriceList,
    state.tournamentData,
    state.firstName,
    state.lastName,
    state.player2Name,
    state.player3Name,
    state.player4Name,
  ]);

  const changePlayer2SwitchBtn = (event) => {
    setState({ player2SwitchBtn: event.target.checked });
  };
  const changePlayer3SwitchBtn = (event) => {
    setState({ player3SwitchBtn: event.target.checked });
  };
  const changePlayer4SwitchBtn = (event) => {
    setState({ player4SwitchBtn: event.target.checked });
  };

  const closeTermConditionModal = () => {
    setState({ termConditionModal: false });
  };
  const closePrivacyPolicyModal = () => {
    setState({ privacyPolicyModal: false });
  };

  const cancle = () => {
    setState({
      firstName: "",
      lastName: "",
      email: "",
      telePhone: "",
    });
    navigate("/tournament");
  };

  const submitOk = (e) => {
    e.preventDefault();
    if (state.checkBox) {
      dispatch(payLoader(true));
      if (state.teeTimeISBooked) {
        dispatch(payLoader(false));
        setState({
          alertMessage: true,
          alertMessageTxt:
            "This teetimes is booked by some one please try again with another tee time !",
        });
      } else {
        dispatch(payLoader(false));
        setState({ payClick: true });
      }
    }
  };

  const saveDetails = () => {
    let localUserData = {
      email: state.email,
      lastName: state.lastName,
      checkBox: state.checkBox,
      handicap: state.handicap,
      firstName: state.firstName,
      telePhone: state.telePhone,
      cdhNumber: state.cdhNumber,
      player2Name: state.player2Name,
      player3Name: state.player3Name,
      player4Name: state.player4Name,
      player2Handicap: state.player2Handicap,
      player3Handicap: state.player3Handicap,
      player4Handicap: state.player4Handicap,
      specialRequests: state.specialRequests,
      player2CDHNumber: state.player2CDHNumber,
      player3CDHNumber: state.player3CDHNumber,
      player4CDHNumber: state.player4CDHNumber,
    };
    set("localUserData", JSON.stringify(localUserData));
  };

  const CustomInput = (props) => {
    return (
      <div className="w-full ">
        <label className="text-[15px] text-white255a font-[Cambay] mb-1 flex flex-row">
          {props.title}{" "}
          {props.optional ? (
            <span className="text-[#ffffff7a] text-sm font-[Cambay]">
              {"(optional)"}
            </span>
          ) : (
            ""
          )}
          {props.require ? (
            <Tooltip title={`Please fill in this field.`} arrow>
              <div className="cursor-pointer">
                <span className="text-[#ff6d6d] text-sm pl-[1px] font-[Cambay]">
                  {" *"}
                </span>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </label>
        <input
          readOnly={props.readOnly}
          placeholder={props.placeholder ? props.placeholder : props.title}
          className={txtInput}
          type={props.type ? props.type : "text"}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          required={props.require}
          onInvalid={props.onInvalid}
          onInput={(e) => e.target.setCustomValidity("")}
          autoComplete="off"
          role="presentation"
        />
      </div>
    );
  };

  const DetailTxt = (props) => {
    return (
      <div
        className={`w-full h-[48px] flex items-center justify-start px-2 bg-primary rounded-lg `}
      >
        <PrimaryText className={`text-[18px] font-medium text-white `}>
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const DetailText = (props) => {
    return (
      <Tooltip
        title={props.title}
        arrow
        placement="top"
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "0px",
                },
            },
          },
        }}
      >
        <div
          className={`w-full h-[48px] flex items-center justify-start px-2 bg-white rounded-lg`}
        >
          <PrimaryText className={`text-[18px] truncate`}>
            {props.title}
          </PrimaryText>
        </div>
      </Tooltip>
    );
  };

  const BookingForm = () => {
    return (
      <>
        <form onSubmit={submitOk}>
          <InputView>
            <div
              className={`${
                playersPriceList?.length === 1 ? "w-full" : "md3:w-[50%] w-full"
              } flex flex-col`}
            >
              <div className="mb-4">
                <PrimaryText className={inputTitle}>
                  {t("tournamentCheckout.player1")}
                </PrimaryText>
              </div>

              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row flex-col gap-x-6`
                    : ""
                }
              >
                {CustomInput({
                  title: t("checkOut.completeBooking.fName"),
                  value: state.firstName,
                  onChange: (event) => {
                    setState({ firstName: event.target.value });
                    saveDetails();
                  },
                  maxLength: 30,
                  require: true,
                  onInvalid: (e) => {
                    e.target.setCustomValidity(
                      t("checkOut.completeBooking.enterFName")
                    );
                  },
                })}
                {CustomInput({
                  title: t("checkOut.completeBooking.lName"),
                  value: state.lastName,
                  onChange: (event) => {
                    setState({ lastName: event.target.value });
                    saveDetails();
                  },
                  maxLength: 30,
                  require: true,
                  onInvalid: (e) => {
                    e.target.setCustomValidity(
                      t("checkOut.completeBooking.enterLName")
                    );
                  },
                })}
              </div>
              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row flex-col gap-x-6`
                    : ""
                }
              >
                {CustomInput({
                  title: t("checkOut.completeBooking.email"),
                  value: state.email,
                  type: "email",
                  onChange: (event) => {
                    setState({ email: event.target.value });
                    saveDetails();
                  },
                  require: true,
                  onInvalid: (e) => {
                    e.target.setCustomValidity(
                      t("checkOut.completeBooking.enterEmail")
                    );
                  },
                  maxLength: 40,
                })}
                {CustomInput({
                  title: t("tournamentCheckout.mobileNo"),
                  value: state.telePhone,
                  onChange: (event) => {
                    const regex = /^[0-9+-.\b]+$/;
                    if (
                      event.target.value === "" ||
                      regex.test(event.target.value)
                    ) {
                      setState({ telePhone: event.target.value });
                      saveDetails();
                    }
                  },
                  maxLength: 15,
                  require: true,
                  onInvalid: (e) => {
                    e.target.setCustomValidity(
                      t("tournamentCheckout.mobileNo")
                    );
                  },
                })}
              </div>
              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row flex-col gap-x-6`
                    : ""
                }
              >
                {CustomInput({
                  title: t("tournamentCheckout.handicap"),
                  value: state.handicap,
                  onChange: (event) => {
                    const regex = /^[0-9+-.\b]+$/;
                    if (
                      event.target.value === "" ||
                      regex.test(event.target.value)
                    ) {
                      setState({ handicap: event.target.value });
                      saveDetails();
                    }
                  },
                  maxLength: 5,
                })}
                {CustomInput({
                  title: t("tournamentCheckout.club"),
                  value: state.club,
                  onChange: (event) => {
                    setState({ club: event.target.value });
                  },
                  maxLength: 30,
                  readOnly: true,
                })}
              </div>
              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row flex-col gap-x-6`
                    : ""
                }
              >
                <div className="w-full">
                  <label className="text-[15px] text-white255a font-[Cambay] mb-1 flex flex-row">
                    {t("tournamentCheckout.selectContry")}{" "}
                  </label>
                  <Autocomplete
                    openOnFocus
                    blurOnSelect
                    className="w-full h-[45px] mb-4"
                    sx={{
                      display: "inline-block",
                      "& input": {
                        bgcolor: "background.paper",
                        color: (theme) =>
                          theme.palette.getContrastText(
                            theme.palette.background.paper
                          ),
                      },
                    }}
                    id="custom-input-demo"
                    options={state.countryList}
                    value={state.country}
                    onChange={(event, newValue) => {
                      setState({ country: newValue, countryDropdown: true });
                      saveDetails();
                    }}
                    onFocus={() => setState({ countryDropdown: true })}
                    onClose={() => setState({ countryDropdown: false })}
                    renderInput={(params) => (
                      <div
                        ref={params.InputProps.ref}
                        className="w-full flex relative"
                      >
                        <input
                          type="text"
                          {...params.inputProps}
                          className={txtInput}
                          style={{ width: "100%" }}
                          placeholder={t("tournamentCheckout.selectContry")}
                          required={true}
                        />
                        <div
                          className={`w-fit h-fit absolute right-0 top-1 ${
                            state.countryDropdown
                              ? "duration-500 transform rotate-180"
                              : "duration-500 transform rotate-0"
                          }`}
                        >
                          <ExpandMoreIcon
                            fontSize="large"
                            sx={{ color: "#757680" }}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="w-full">
                  <label className="text-[15px] text-white255a font-[Cambay] mb-1 flex flex-row">
                    {t("tournamentCheckout.selectCountie")}{" "}
                  </label>
                  <Autocomplete
                    openOnFocus
                    blurOnSelect
                    className="w-full h-[45px] mb-4 "
                    sx={{
                      display: "inline-block",
                      "& input": {
                        bgcolor: "background.paper",
                        color: (theme) =>
                          theme.palette.getContrastText(
                            theme.palette.background.paper
                          ),
                      },
                    }}
                    id="custom-input-demo"
                    options={state.countyList}
                    disabled={state.countyList?.length < 1}
                    value={state.county}
                    onChange={(event, newValue) => {
                      setState({ county: newValue, countyDropdown: true });
                      saveDetails();
                    }}
                    onFocus={() => setState({ countyDropdown: true })}
                    onClose={() => setState({ countyDropdown: false })}
                    renderInput={(params) => (
                      <div
                        ref={params.InputProps.ref}
                        className="w-full flex relative"
                      >
                        <input
                          type="text"
                          {...params.inputProps}
                          style={{ width: "100%" }}
                          className={`${txtInput} ${
                            state.countyList?.length < 1
                              ? "hover:cursor-not-allowed opacity-60"
                              : "hover:cursor-text"
                          }`}
                          placeholder={t("tournamentCheckout.selectCountie")}
                        />
                        {state.countyList?.length > 0 && (
                          <div
                            className={`w-fit h-fit absolute right-0 top-1 ${
                              state.countyDropdown
                                ? "duration-500 transform rotate-180"
                                : "duration-500 transform rotate-0"
                            }`}
                          >
                            <ExpandMoreIcon
                              fontSize="large"
                              sx={{ color: "#757680" }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row-reverse flex-col gap-x-6`
                    : ""
                }
              >
                <div className="w-full"></div>
                {CustomInput({
                  title:
                    state.country?.label === "Ireland"
                      ? t("tournamentCheckout.cdhNo")
                      : "PlayerId",
                  value: state.cdhNumber,
                  onChange: (event) => {
                    setState({ cdhNumber: event.target.value });
                    saveDetails();
                  },
                  maxLength: 25,
                })}
              </div>
              <div
                className={
                  playersPriceList?.length === 1
                    ? `w-full h-auto flex md3:flex-row-reverse flex-col gap-x-6`
                    : ""
                }
              >
                <div className="w-full">
                  <div className="my-4">
                    <PrimaryText className={inputTitle}>
                      {t("checkOut.completeBooking.specialRequest")}
                    </PrimaryText>
                  </div>
                  <textarea
                    value={state.specialRequests}
                    onChange={(event) => {
                      setState({ specialRequests: event.target.value });
                      saveDetails();
                    }}
                    rows={6}
                    placeholder={t("checkOut.completeBooking.insertYour")}
                    className={txtInput}
                  ></textarea>
                </div>
              </div>

              <CheckboxView className="w-auto h-auto flex flex-row items-center">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    required
                    checked={state.checkBox}
                    color="success"
                    sx={{ color: "white" }}
                    onChange={(event) => {
                      setState({ checkBox: event.target.checked });
                    }}
                  />
                </ThemeProvider>
                <PrimaryText className="text-white255 font-[Cambay] font-medium">
                  {t("checkOut.completeBooking.iAgree")}
                  <span
                    className="text-red255 underline font-medium ml-1 hover:cursor-pointer font-[Cambay]"
                    onClick={() => setState({ termConditionModal: true })}
                  >
                    {" "}
                    {t("checkOut.completeBooking.termCondition")}
                  </span>{" "}
                  {t("tournamentCheckout.and")}{" "}
                  <span
                    className="text-red255 underline font-medium ml-1 font-[Cambay] hover:cursor-pointer"
                    onClick={() => setState({ privacyPolicyModal: true })}
                  >
                    {t("checkOut.completeBooking.privacyPolicy")}
                  </span>
                  {"."}
                </PrimaryText>
              </CheckboxView>
            </div>
            {playersPriceList?.length >= 2 && (
              <div className="md3:w-[50%] w-full flex flex-col max-h-[1000px] overflow-y-auto custom-scroll">
                {playersPriceList?.length >= 2 && (
                  <React.Fragment>
                    <div className="mb-4 w-full h-auto flex flex-col md3:mt-0 mt-8">
                      <div className="w-full h-auto flex flex-row gap-x-2 items-center">
                        <PrimaryText className="text-white255 text-lg">
                          {t("tournamentCheckout.sameDetail")}
                        </PrimaryText>
                        <span
                          className={`${
                            state.player2SwitchBtn ? "hidden" : "flex"
                          } text-white255 text-md ml-[10px] mr-[-13px] font-[Cambay]`}
                        >
                          {"off"}
                        </span>
                        <ThemeProvider theme={theme}>
                          <Switch
                            checked={state.player2SwitchBtn}
                            onChange={changePlayer2SwitchBtn}
                            inputProps={{ "aria-label": "controlled" }}
                            color="secondary"
                          />
                        </ThemeProvider>
                        <span
                          className={`${
                            state.player2SwitchBtn ? "flex" : "hidden"
                          } text-white255 text-md ml-[-13px] mr-[10px] font-[Cambay]`}
                        >
                          {"on"}
                        </span>
                      </div>
                      <PrimaryText className={inputTitle}>
                        {t("tournamentCheckout.player2")}
                      </PrimaryText>
                    </div>
                    {CustomInput({
                      title: t("tournamentCheckout.name"),
                      value: state.player2Name,
                      onChange: (event) => {
                        const regex = /^[a-zA-Z\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player2Name: event.target.value });
                          saveDetails();
                        }
                      },
                      maxLength: 30,
                      readOnly: state.player2SwitchBtn,
                    })}
                    {CustomInput({
                      title: t("tournamentCheckout.handicap"),
                      value: state.player2Handicap,
                      onChange: (event) => {
                        const regex = /^[0-9+-.\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player2Handicap: event.target.value });
                          saveDetails();
                        }
                      },
                      maxLength: 5,
                    })}
                    {CustomInput({
                      title:
                        state.country?.label === "Ireland"
                          ? t("tournamentCheckout.cdhNo")
                          : "PlayerId",
                      value: state.player2CDHNumber,
                      onChange: (event) => {
                        setState({ player2CDHNumber: event.target.value });
                        saveDetails();
                      },
                      maxLength: 25,
                    })}
                  </React.Fragment>
                )}

                {playersPriceList?.length >= 3 && (
                  <React.Fragment>
                    <div className="mb-4 w-full h-auto flex flex-col mt-8">
                      <div className="w-full h-auto flex flex-row gap-x-1 items-center">
                        <PrimaryText className="text-white255 text-lg">
                          {t("tournamentCheckout.sameDetail")}
                        </PrimaryText>
                        <span
                          className={`${
                            state.player3SwitchBtn ? "hidden" : "flex"
                          } text-white255 text-md ml-[10px] mr-[-13px] font-[Cambay]`}
                        >
                          {"off"}
                        </span>
                        <ThemeProvider theme={theme}>
                          <Switch
                            checked={state.player3SwitchBtn}
                            onChange={changePlayer3SwitchBtn}
                            inputProps={{ "aria-label": "controlled" }}
                            color="secondary"
                          />
                        </ThemeProvider>
                        <span
                          className={`${
                            state.player3SwitchBtn ? "flex" : "hidden"
                          } text-white255 text-md ml-[-7px] font-[Cambay]`}
                        >
                          {"on"}
                        </span>
                      </div>
                      <PrimaryText className={inputTitle}>
                        {t("tournamentCheckout.player3")}
                      </PrimaryText>
                    </div>
                    {CustomInput({
                      title: t("tournamentCheckout.name"),
                      value: state.player3Name,
                      onChange: (event) => {
                        const regex = /^[a-zA-Z\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player3Name: event.target.value });
                          saveDetails();
                        }
                      },
                      readOnly: state.player3SwitchBtn,
                      maxLength: 30,
                    })}
                    {CustomInput({
                      title: t("tournamentCheckout.handicap"),
                      value: state.player3Handicap,
                      onChange: (event) => {
                        const regex = /^[0-9+-.\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player3Handicap: event.target.value });
                          saveDetails();
                        }
                      },
                      maxLength: 5,
                    })}
                    {CustomInput({
                      title:
                        state.country?.label === "Ireland"
                          ? t("tournamentCheckout.cdhNo")
                          : "PlayerId",
                      value: state.player3CDHNumber,
                      onChange: (event) => {
                        setState({ player3CDHNumber: event.target.value });
                        saveDetails();
                      },
                      maxLength: 25,
                    })}
                  </React.Fragment>
                )}

                {playersPriceList?.length >= 4 && (
                  <React.Fragment>
                    <div className="mb-4 w-full h-auto flex flex-col mt-8">
                      <div className="w-full h-auto flex flex-row gap-x-1 items-center">
                        <PrimaryText className="text-white255 text-lg">
                          {t("tournamentCheckout.sameDetail")}
                        </PrimaryText>
                        <span
                          className={`${
                            state.player4SwitchBtn ? "hidden" : "flex"
                          } text-white255 text-md ml-[10px] mr-[-13px] font-[Cambay]`}
                        >
                          {"off"}
                        </span>
                        <ThemeProvider theme={theme}>
                          <Switch
                            checked={state.player4SwitchBtn}
                            onChange={changePlayer4SwitchBtn}
                            inputProps={{ "aria-label": "controlled" }}
                            color="secondary"
                          />
                        </ThemeProvider>
                        <span
                          className={`${
                            state.player4SwitchBtn ? "flex" : "hidden"
                          } text-white255 text-md ml-[-7px] font-[Cambay]`}
                        >
                          {"on"}
                        </span>
                      </div>
                      <PrimaryText className={inputTitle}>
                        {t("tournamentCheckout.player4")}
                      </PrimaryText>
                    </div>
                    {CustomInput({
                      title: t("tournamentCheckout.name"),
                      value: state.player4Name,
                      onChange: (event) => {
                        const regex = /^[a-zA-Z\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player4Name: event.target.value });
                          saveDetails();
                        }
                      },
                      readOnly: state.player4SwitchBtn,
                      maxLength: 30,
                    })}
                    {CustomInput({
                      title: t("tournamentCheckout.handicap"),
                      value: state.player4Handicap,
                      onChange: (event) => {
                        const regex = /^[0-9+-.\b]+$/;
                        if (
                          event.target.value === "" ||
                          regex.test(event.target.value)
                        ) {
                          setState({ player4Handicap: event.target.value });
                          saveDetails();
                        }
                      },
                      maxLength: 5,
                    })}
                    {CustomInput({
                      title:
                        state.country?.label === "Ireland"
                          ? t("tournamentCheckout.cdhNo")
                          : "playerId",
                      value: state.player4CDHNumber,
                      onChange: (event) => {
                        setState({ player4CDHNumber: event.target.value });
                        saveDetails();
                      },
                      maxLength: 25,
                    })}
                  </React.Fragment>
                )}
              </div>
            )}
          </InputView>
          <Line />
          <div className="mb-4">
            <PrimaryText className={inputTitle}>
              {t("tournamentCheckout.bookingDetail")}
            </PrimaryText>
          </div>
          <BookingView>
            <div className="w-[50%] h-auto flex flex-col gap-y-4 items-center ">
              <DetailTxt title={t("tournamentCheckout.tournament")} />
              <DetailTxt title={t("tournamentCheckout.competition")} />
              <DetailTxt title={t("tournamentCheckout.bookingDate")} />
              <DetailTxt title={t("tournamentCheckout.holes")} />
              <DetailTxt title={t("tournamentCheckout.noOfPlayer")} />
              <DetailTxt title={t("tournamentCheckout.totalBookingFee")} />
            </div>
            <div className="w-[50%] h-auto flex flex-col gap-y-4 items-center ">
              <DetailText
                title={state.tournamentData?.data?.competitionId?.title}
              />
              <DetailText title={state.tournamentData?.data?.title} />
              <DetailText
                title={moment(state.tournamentData?.teeTime?.data?.time).format(
                  "YYYY-MM-DD / hh:mm"
                )}
              />
              <DetailText title={state.tournamentData?.data?.holesToPlay} />
              <DetailText title={state.tournamentData?.slot?.id} />
              <DetailText title={"€ " + totalPrice} />
            </div>
          </BookingView>

          <Elements stripe={stripePromise} options={paymentOptions}>
            <PaymentDetails
              onPayClick={() => setState({ payClick: false })}
              inputComplete={state.payClick}
              compation_Data={state}
              type={"tournament"}
            />
          </Elements>

          <BtnView>
            <Cancle type="button" onClick={cancle} disabled={paymentLoader}>
              <PrimaryText className={"text-white255 text-lg"}>
                {t("checkOut.booking.cancel")}
              </PrimaryText>
            </Cancle>
            <Submit
              type="submit"
              disabled={paymentLoader}
              className={`${
                paymentLoader
                  ? " opacity-75"
                  : "hover:from-primary hover:to-primary hover:cursor-pointer"
              }`}
            >
              {paymentLoader ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="3"
                  animationDuration="0.85"
                  width="30"
                  visible={true}
                />
              ) : (
                <PrimaryText className={"text-white255 text-lg"}>
                  {t("checkOut.booking.submit")}
                </PrimaryText>
              )}
            </Submit>
          </BtnView>
        </form>
        <TermAndConditionModal
          openModal={state.termConditionModal}
          closeModal={closeTermConditionModal}
        />
        <PrivacyPolicyModal
          openModal={state.privacyPolicyModal}
          closeModal={closePrivacyPolicyModal}
        />
      </>
    );
  };

  return (
    <Component>
      <CompleteBookingView>
        <div className="mb-[10px]">
          <PrimaryText className="text-white255 text-lg font-semibold uppercase">
            {t("checkOut.completeBooking.completeYourBooking")}
          </PrimaryText>
        </div>
        <Container>{BookingForm()}</Container>
        {state.alertMessageTxt && (
          <TostMessage
            open={state.alertMessage}
            onClose={() =>
              setState({ alertMessage: false, alertMessageTxt: null })
            }
            title={state.alertMessageTxt}
            type={"info"}
          />
        )}
      </CompleteBookingView>

      <BookingDetailView>
        <ClubDetails />
      </BookingDetailView>
    </Component>
  );
}

const Component = tw.div`
md:px-8 sm:px-4 px-2
mt-4
h-auto
w-full
flex
lg2:flex-row
flex-col
lg2:items-start
items-center
justify-center
gap-x-6
`;

const Container = tw.div`
w-full 
h-auto 
flex flex-col 
bg-boxBackground 
rounded-xl 
shadow-xl 
md3:p-4 p-2
`;
const CompleteBookingView = tw.div`
lg2:w-[72%] w-full 
h-auto 
`;
const BookingDetailView = tw.div`
lg2:w-[28%]
w-full
h-auto
max-w-[360px]
flex
rounded-lg
lg2:mt-0
mt-10
`;
const txtInput = `
w-full 
bg-white255 
text-textColor2 
border-[2px] 
rounded-lg
py-[11px]
mb-[12px]
px-4 
leading-tight 
focus:outline-none 
border-[#ffffff]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;
const inputTitle = `
text-white255 
text-xl 
font-semibold
`;
const InputView = tw.div`
w-full 
h-auto 
flex  
md3:flex-row  flex-col 
items-start 
justify-between 
gap-x-4 
gap-y-4
`;
const BookingView = tw.div`
lg:w-[65%] w-full 
h-auto 
flex 
flex-row 
items-center 
justify-start 
gap-x-4 
mb-14
`;
const BtnView = tw.div`
flex 
flex-row 
justify-between 
items-cetner
w-full 
h-[46px] 
mt-[60px]
mb-4
`;
const Submit = tw.button`
md:w-[30%] w-fit px-10
h-[44px]
rounded-[23px]
bg-gradient-to-r from-primary to-[#725e40]
flex
justify-center
items-center
webkitHighlight
`;
const Cancle = tw.button`
md:w-[30%] w-fit px-10
h-[44px]
rounded-[23px]
bg-gradient-to-r from-red-400 to-red255
hover:from-red255 hover:to-red255
flex
justify-center
items-center
webkitHighlight
`;
const CheckboxView = tw.div`
w-auto 
h-auto 
flex 
flex-row 
items-center
mt-2
`;
const Line = tw.div`
w-full
h-[1px]
bg-primary
my-8
`;

export default CheckOutForm;
