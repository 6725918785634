import React from "react";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import ProfileView from "./widgets/profileView";

function ClubDetails() {
  const clubData = useSelector((state) => state.clubs.clubsData);

  return (
    <React.Fragment>
      {clubData && clubData?.id ? (
        <Container>
          <ProfileView props={clubData} />
        </Container>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

const Container = tw.div`
bg-boxBackground
mt-[6px]
mb-[33px]
w-full
rounded-2xl
shadow-xl
drop-shadow-xl
`;

export default ClubDetails;
