import React, { useEffect, lazy, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Header, Footer } from "@containers";
import { UseNetworkStatus, get } from "@utils";
import { getBGimage, getClubsData, getHeaderData, getMenus } from "@store";
import routes from "./route";
import "./App.css";

const NotFound = lazy(() => import("@components/notFound"));

function App() {
  const dispatch = useDispatch();
  const { isOnline } = UseNetworkStatus();
  const { i18n } = useTranslation("common");
  const bgImage = useSelector((state) => state.setting.bgImage);
  const isImg = bgImage?.data?.data?.bgImage?.url;
  const data = useSelector((state) => state.header.headerData);
  const headerMenu = useSelector((state) => state.menu.menus);
  const clubData = useSelector((state) => state.clubs.clubsData);

  const [isServerError, setIsServerError] = useState(false);
  const [isServerLoading, setIsServerLoading] = useState(false);

  useEffect(() => {
    dispatch(getBGimage());
    dispatch(getHeaderData());
    dispatch(getMenus());
    dispatch(getClubsData());

    const selectLanguage = get("Language");
    if (selectLanguage !== null) {
      i18n.changeLanguage(selectLanguage);
    }
  }, [i18n, dispatch]);

  useEffect(() => {
    if (
      !bgImage?.data?.data?.id &&
      !clubData?.id &&
      !headerMenu?.length &&
      !data?.id
    ) {
      setIsServerLoading(true);
    } else {
      setIsServerError(false);
      setIsServerLoading(false);
    }
  }, [bgImage, headerMenu, data, clubData]);

  useEffect(() => {
    let interval = null;

    if (isServerLoading) {
      interval = setInterval(() => {
        setIsServerError(true);
      }, 5000);
      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
      setIsServerError(false);
    }
  }, [isServerLoading]);

  return (
    <div>
      {isServerError ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "white",
          }}
        >
          <div>
            <h1
              style={{
                marginTop: "50px",
                color: "gray",
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Welcome To Ring of Kerry Golf Club{" "}
            </h1>
          </div>
          <div
            style={{
              marginTop: "50px",
              color: "#9C8053",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            <h1 className="animate-pulse text-center">
              Server Under Maintenance{" "}
            </h1>
          </div>
        </div>
      ) : isServerLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-[#9C8053]" />
        </div>
      ) : (
        <div
          style={{
            backgroundImage: `url(${isImg})`,
          }}
          className="background-Img"
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              margin: "auto",
              width: "full",
              height: "full",
              backgroundColor: "rgba(18,16,55,0.50)",
            }}
          >
            {isOnline ? (
              <div
                style={{
                  width: "100%",
                  maxWidth: "1600px",
                  height: "auto",
                  minHeight: "100vh",
                }}
              >
                <Routes>
                  {routes.map((item, index) => (
                    <Route
                      key={"" + index}
                      exact={item.exact}
                      isPublic={item.isPublic}
                      path={item.path}
                      name={item.name}
                      element={
                        <>
                          <Header />
                          <div
                            style={{
                              minHeight: window.innerHeight * 0.67,
                              maxWidth: "1500px",
                              width: "100%",
                              margin: "auto",
                            }}
                          >
                            {item.element}
                          </div>
                          <Footer />
                        </>
                      }
                    />
                  ))}
                  <Route
                    exact={true}
                    isPublic={true}
                    path={"*"}
                    name={"NotFound"}
                    element={<NotFound />}
                  />
                </Routes>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  margin: "auto",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <h1
                    style={{
                      marginTop: "50px",
                      color: "gray",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Welcome To Ring of Kerry Golf Club{" "}
                  </h1>
                </div>
                <div
                  style={{
                    marginTop: "50px",
                    color: "green",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  <h1 className="animate-pulse ">Your Are Offline </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
