import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";

function Setting() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const loginData = useSelector((state) => state.users.login);
  const registerData = useSelector((state) => state.users.register);
  const userStatus = useSelector((state) => state.users.status);

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users === null) {
      navigate("/");
    }
  }, [navigate, loginData, registerData, userStatus]);

  return (
    <Root>
      <Line />
      <Container>
        <PrimaryText className={title}>{t("header.setting")}</PrimaryText>
        <div className={component}>
          <OptionButton onClick={() => navigate("/setting/changeLanguage")}>
            <span className="font-[Cambay] font-medium">
              {t("setting.changeLanguage")}
            </span>
          </OptionButton>
          <OptionButton onClick={() => navigate("/setting/change-password")}>
            <span className="font-[Cambay] font-medium">
              {t("setting.changePassword")}
            </span>
          </OptionButton>
        </div>
      </Container>
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
h-full
w-full
`;
const Container = tw.div`
h-[500px] 
w-full 
flex flex-col 
items-center
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-primary
my-2
`;
const title = `
text-2xl 
text-textColor 
font-bold 
font-[Cambay] 
text-center 
p-4
`;
const component = `
h-auto 
w-[350px] 
bg-boxBackground 
rounded-xl 
flex flex-col 
items-center 
pb-6 
font-[Cambay]
`;
const OptionButton = tw.button`
rounded-full
mt-6
pb-[7px]
pt-[10px]
text-center
justify-center
shadow-md
webkitHighlight
bg-gray-300
hover:bg-gray-200
w-[80%]
font-medium
`;

export default Setting;
