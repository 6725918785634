import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { PrimaryText } from "@components";
import userProfile from "@assets/image/userProfile.jpg";
import ReadMore from "../readMore";

const TournamentDetailsCard = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const RenderText = ({ item, value }) => {
    return (
      <div className="w-full h-auto flex flex-row item-start md:justify-end justify-start gap-x-2 bg-[white] md:text-right text-left">
        <p className="text-md text-[#969696] font-[Cambay]">
          <span className="uppercase font-semibold">{item} </span> {value}
        </p>
      </div>
    );
  };

  const Content = () => {
    return (
      <div className="md:w-[35%] w-full h-auto flex flex-col md:items-end items-start md:pl-0 pl-2 pr-3">
        <div className="w-full md:hidden flex flex-col mb-2">
          {data?.type && (
            <RenderText item={t("tournament.type")} value={data?.type} />
          )}
          {data?.numberOfPlayers && (
            <RenderText
              item={t("tournament.players")}
              value={data?.numberOfPlayers}
            />
          )}
          {data?.holesToPlay && (
            <RenderText
              item={t("tournament.holes")}
              value={data?.holesToPlay}
            />
          )}
        </div>

        <div className={txtView}>
          <PrimaryText className={txt}>{t("tournament.date")}</PrimaryText>
          <PrimaryText className={lightTxt}>
            {moment(data?.tournamentDate).format("DD/MM/YYYY")}
          </PrimaryText>
        </div>

        {data?.courseId?.courseName && (
          <RenderText
            item={t("tournament.course")}
            value={data?.courseId?.courseName}
          />
        )}
        {data?.guestPrice && (
          <RenderText
            item={t("tournament.guestPrice")}
            value={`${getSymbolFromCurrency("EUR")} ${data?.guestPrice}`}
          />
        )}
        {data?.memberPrice && (
          <RenderText
            item={t("tournament.memberPrice")}
            value={`${getSymbolFromCurrency("EUR")} ${data?.memberPrice}`}
          />
        )}
        {
          <button
            className="flex items-end"
            onClick={() => navigate("/tournament")}
          >
            <KeyboardBackspaceIcon className="text-primary" />

            <PrimaryText className={lightTxt + "text-primary"}>
              {t("tournament.editSlot")}
            </PrimaryText>
          </button>
        }
      </div>
    );
  };

  return (
    <>
      {data?.title && (
        <div className={cardView}>
          <div className="md:w-[65%] w-full h-auto flex flex-row items-start gap-x-1">
            <div className={img_view}>
              <img
                src={data?.image?.url ? `${data?.image?.url}` : userProfile}
                alt="img"
                className="w-full h-full rounded-md object-cover"
              />
            </div>
            <div className="w-auto flex flex-col pl-[10px]">
              <PrimaryText className="text-[18px] font-[Cambay] font-semibold  leading-5 mb-2">
                {data?.title?.charAt(0).toUpperCase() + data?.title?.slice(1)}
              </PrimaryText>

              {data?.description ? (
                <PrimaryText className={`${lightTxt} mb-2`}>
                  <ReadMore text={data?.description} limit={218} />
                </PrimaryText>
              ) : (
                <></>
              )}

              <div className="w-auto md:flex hidden flex-col">
                {data?.type && (
                  <div className="w-full h-auto flex flex-row item-start justify-start gap-x-2 bg-[white] text-left">
                    <p className="text-md text-[#969696] font-[Cambay]">
                      <span className="uppercase font-semibold">
                        {t("tournament.type")}{" "}
                      </span>{" "}
                      {data?.type}
                    </p>
                  </div>
                )}
                {data?.numberOfPlayers && (
                  <div className="w-full h-auto flex flex-row item-start justify-start gap-x-2 bg-[white] text-left">
                    <p className="text-md text-[#969696] font-[Cambay]">
                      <span className="uppercase font-semibold">
                        {t("tournament.players")}{" "}
                      </span>{" "}
                      {data?.numberOfPlayers}
                    </p>
                  </div>
                )}
                {data?.holesToPlay && (
                  <div className="w-full h-auto flex flex-row item-start justify-start gap-x-2 bg-[white] text-left">
                    <p className="text-md text-[#969696] font-[Cambay]">
                      <span className="uppercase font-semibold">
                        {t("tournament.holes")}{" "}
                      </span>{" "}
                      {data?.holesToPlay}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Content />
        </div>
      )}
    </>
  );
};

const cardView = `
w-full
h-auto
flex
flex-wrap
justify-between
items-start
gap-y-4
rounded-xl
px-[5px]
pb-4
pt-6
bg-white255
`;

const component = `
flex 
flex-row 
items-start 
justify-end 
w-auto
gap-x-1
`;

const img_view = `
w-[70px] 
h-[60px] 
min-w-[65px] 
min-h-[55px] 
rounded-md 
border border-[#969696]
ml-2
object-cover
bg-[pink]
`;

const lightTxt = `
text-md
text-[#969696] 
leading-5
`;

const txtView = `
flex 
flex-row 
items-center 
justify-center 
gap-x-2
`;

const txt = `
text-md
font-[Cambay] 
font-semibold 
text-[#969696] 
leading-6 
text-end
uppercase
`;

export default TournamentDetailsCard;
