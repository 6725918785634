import { useState } from "react";

const ReadMore = ({ text, limit = 218 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const previewText = text?.slice(0, limit);

  return (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: isExpanded
            ? text?.replace(/\n/g, "<br />")
            : previewText?.replace(/\n/g, "<br />") +
              (text?.length > limit ? "..." : ""),
        }}
      />
      {text?.length > limit && (
        <span
          className="text-blue-500 hover:text-blue-800 z-50 cursor-pointer font-semibold ml-1"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </span>
      )}
    </div>
  );
};

export default ReadMore;
