import React, { useReducer } from "react";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    warning: {
      main: "#9C8053",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <ThemeProvider theme={theme}>
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        color="warning"
        {...props}
      />
    </ThemeProvider>
  );
});

function TostMessage(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vertical: "top",
      horizontal: "right",
    }
  );

  const { vertical, horizontal } = state;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props?.open}
      onClose={props?.onClose}
      key={"top" + "right"}
      TransitionComponent={(e) => <Slide {...e} direction="left" />}
      autoHideDuration={15000}
    >
      <Alert
        onClose={props?.onClose}
        severity={props?.type}
        sx={{ width: "100%" }}
      >
        {props?.title}
      </Alert>
    </Snackbar>
  );
}

export default TostMessage;
