import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";
import { CheckLanguage } from "@utils";
import "moment/locale/fr";
import "moment/locale/de";

export const CachedTimeView = ({ lastCahcedDate }) => {
  const { t } = useTranslation("common");
  const [cacheTime, setCacheTime] = useState("");

  let dateLanguage = CheckLanguage() || "en";
  moment.locale(dateLanguage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCacheTime(
        moment(lastCahcedDate).locale(dateLanguage).local().fromNow()
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, [lastCahcedDate, dateLanguage]);

  return (
    <div className={"cursor-default"}>
      {cacheTime && (
        <Root>
          <div className={containt}>
            <PrimaryText className={title}>{t("lastCache")}</PrimaryText>
            <PrimaryText className={txt_value}>{cacheTime}</PrimaryText>
          </div>
        </Root>
      )}
    </div>
  );
};

const Root = tw.div`
w-fit 
h-fit 
flex flex-row 
items-center justify-start 
rounded-full 
gap-x-2 
p-[2px] 
bg-black03
`;

const containt = `
h-full 
w-[150px] 
px-2 
flex flex-col 
items-start justify-center 
overflow-hidden
`;

const title = `
leading-4 
text-white255 
opacity-80 
text-[12px] 
pl-[5px]
`;

const txt_value = `
leading-4 
text-white255 
font-semibold 
text-[15px] 
pl-[5px]
`;
