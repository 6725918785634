export const BOOK_TOURNAMENT = "BOOK_TOURNAMENT";
export const TOURNAMENT_LOADER = "TOURNAMENT_LOADER";
export const GET_ALL_TOURNAMENT = "GET_ALL_TOURNAMENT";
export const COMPETITION_LOADER = "COMPETITION_LOADER";
export const GET_BOOK_TOURNAMENT = "GET_BOOK_TOURNAMENT";
export const COMPETITION_TEE_TIME = "COMPETITION_TEE_TIME";
export const GET_ALL_COMPETITIONS = "GET_ALL_COMPETITIONS";
export const GET_SINGLE_TOURNAMENT = "GET_SINGLE_TOURNAMENT";
export const GET_SINGLE_COMPETITION = "GET_SINGLE_COMPETITION";
export const GET_TOURNAMENT_TEE_TIME = "GET_TOURNAMENT_TEE_TIME";
export const GET_COMPETITION_TEE_TIME = "GET_COMPETITION_TEE_TIME";
export const REMOVE_COMPETITION_TEE_TIME = "REMOVE_COMPETITION_TEE_TIME";
export const REMOVE_SINGLE_TOURNAMENT = "REMOVE_SINGLE_TOURNAMENT";
export const GET_COMPETITION_LOCAL_TEE_TIME = "GET_COMPETITION_LOCAL_TEE_TIME";
