import React from "react";
import { ClubDetails } from "@containers";
import tw from "tailwind-styled-components";
import PlayerView from "./widgets/playersView";
import CardDetails from "./widgets/cardDetails";

function Players() {
  return (
    <>
      <Root>
        <div className={mainView}>
          <CardDetails />
          <PlayerView />
        </div>
        <div className={clubView}>
          <ClubDetails />
        </div>
      </Root>
    </>
  );
}

const Root = tw.div`
md:px-8 sm:px-4 px-2
mt-4
h-auto
w-full
flex
lg2:flex-row
flex-col
lg2:items-start
items-center
justify-center
gap-x-6
`;
const mainView = `
lg2:w-[72%]
w-full 
h-full 
flex
flex-col 
gap-y-6 
items-center 
justify-start 
mb-6
lg2:mr-3
mr-0
`;
const clubView = `
lg2:w-[28%]
w-full
h-auto
max-w-[360px]
flex
flex-row
items-center
justify-center
rounded-lg
`;

export default Players;
