import React, { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";
import "../../containerStyle.css";

function SuccessModal(props) {
  const navigate = useNavigate();
  const loginData = useSelector((state) => state.users.login);
  const registerData = useSelector((state) => state.users.register);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      status: "",
      message: "",
    }
  );

  useEffect(() => {
    let fName =
      loginData?.status === 200
        ? loginData?.data?.user?.firstName
        : registerData?.status === 200
        ? registerData?.data?.user?.firstName
        : "";
    let lName =
      loginData?.status === 200
        ? loginData?.data?.user?.lastName
        : registerData?.status === 200
        ? registerData?.data?.user?.lastName
        : "";
    let emailId =
      loginData?.status === 200
        ? loginData?.data?.user?.email
        : registerData?.status === 200
        ? registerData?.data?.user?.email
        : "";
    if (loginData) {
      if (loginData?.status === 200) {
        setState({
          status: "Login Successful !",
          message: `Welcome ${fName && lName ? fName + " " + lName : emailId}`,
        });
      } else {
        setState({
          status: "Login Failed",
          message: `Login failed because invalid email or password`,
        });
      }
    } else if (registerData) {
      if (registerData?.status === 200) {
        setState({
          status: "Registration Successful !",
          message: `Welcome ${fName && lName ? fName + " " + lName : emailId}`,
        });
      } else {
        setState({
          status: "Registration Failed",
          message: `Registration failed because ${registerData?.response?.data?.error?.message}`,
        });
      }
    } else {
      setState({
        status: null,
        message: "",
      });
    }
  }, [loginData, registerData]);

  const closeModal = () => {
    if (state.status === "Login Failed") {
      props.closeLoginErrorModal();
    } else if (state.status === "Registration Failed") {
      props.closeRegisterErrorModal();
    } else if (
      state.status === "Registration Successful !" ||
      state.status === "Login Successful !"
    ) {
      props.closeModal();
      navigate("/");
    } else {
      props.closeModal();
    }
    setState({
      status: null,
      message: "",
    });
  };

  const Title = (e) => {
    return (
      <div className="flex items-center justify-center w-full h-auto mb-4">
        <PrimaryText className="text-2xl text-textColor2 font-bold font-[Cambay] text-center">
          {e.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      // onClose={() => closeModal()}
      TransitionComponent={Zoom}
      sx={{
        height: "90%",
        marginTop: "3%",
      }}
    >
      <Content>
        <Title title={state.status} />
        <Line />
        <div className={btn_View}>
          <PrimaryText className="text-textColor2 text-md leading-normal font-[Cambay]">
            {state.message}
          </PrimaryText>
          <button
            className="w-fit h-auto py-2 px-6 rounded-lg bg-[#E5E5E9]"
            onClick={closeModal}
          >
            OK
          </button>
        </div>
      </Content>
    </Dialog>
  );
}

const Content = tw.div`
w-auto
h-auto 
sm:py-4
sm:px-6
sm2:p-[12px]
p-auto
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-[#C5C5C5] 
my-2
`;
const btn_View = `
w-full 
h-auto 
p-4 
flex flex-col 
gap-4 
items-center justify-center
`;

export default SuccessModal;
