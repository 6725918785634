import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import Skeleton from "@mui/material/Skeleton";
import { PrimaryText, HeadingText } from "@components";
import { getListingBookings } from "@store";
import TeeTimeBox from "./teeTimeBox";
import "../../containerStyle.css";

function TeeTimeView(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const updatedDate = useSelector((state) => state.teeTime.date);
  const isCompleteLoading = useSelector(
    (state) => state.teeTime.showCompleteLoder
  );

  useEffect(() => {
    const formDate = moment(updatedDate)
      .subtract(1, "day")
      .format("DD-MM-YYYY");
    const toDate = moment(updatedDate).add(1, "day").format("DD-MM-YYYY");

    let bookingsApiData = {
      fromDate: formDate,
      toDate: toDate,
    };
    dispatch(getListingBookings(bookingsApiData));
  }, [dispatch, updatedDate]);

  const TimeTxt = (e) => {
    return (
      <div className="mt-[6px] mb-[3px] w-full h-auto">
        {e.type === "Morning" ? (
          <div className="w-full h-auto flex flex-row items-center justify-center">
            <HeadingText txtClass="text-white">{t("morning")}</HeadingText>
          </div>
        ) : (
          <div className="w-full h-auto flex flex-row items-center justify-center">
            <HeadingText txtClass="text-white">{t("afternoon")}</HeadingText>
          </div>
        )}
        <div />
      </div>
    );
  };

  const CourseName = (props) => {
    return (
      <div className="flex flex-row items-center justify-center xl2:my-[2px] my-2 w-full">
        <PrimaryText className="text-textColor text-[18px] font-bold">
          {props.children}{" "}
        </PrimaryText>
      </div>
    );
  };

  const BoxLoader = () => {
    return (
      <div className="w-[90px] h-[67px] rounded-lg mt-2 mr-2">
        <Skeleton
          variant="rounded"
          sx={{ bgcolor: "#ffffff5c", borderRadius: "10px" }}
          animation="wave"
          width={90}
          height={67}
        />
      </div>
    );
  };

  return (
    <Root>
      {props?.data?.map((item, index) => (
        <React.Fragment key={`teeTimes${index}`}>
          <div
            className={`${
              props?.data?.length < 2 ? "w-full h-fit" : "w-full h-full "
            }`}
          >
            {
              <CourseName>
                {item[0].courseName || item[1].courseName || ""}
              </CourseName>
            }

            <Container
              className={`${
                props?.data?.length < 2
                  ? "w-auto lg2:min-h-[400px] h-fit "
                  : "md:w-auto w-full md:h-[500px] h-fit"
              }`}
            >
              <div className={component}>
                {item.map((teeItem, index2) => (
                  <React.Fragment key={`teesTimes${index2}`}>
                    {teeItem?.data?.length > 0 && (
                      <TimeTxt type={teeItem?.type} />
                    )}
                    {teeItem?.data?.map((data, index3) => (
                      <React.Fragment key={`teeTime${index3}`}>
                        <TeeTimeBox
                          teeTimeData={data}
                          isSelect={addToCartData.find(
                            (e) =>
                              e?.teeTime?.teeTimeData?.id === data?.id &&
                              e?.teeTime?.teeTimeData?.course ===
                                data?.course &&
                              e?.teeTime?.teeTimeData?.time === data?.time
                          )}
                        />
                        {teeItem?.data?.length - 1 === index3 &&
                          isCompleteLoading && <BoxLoader />}
                        {teeItem?.data?.length - 1 === index3 &&
                          isCompleteLoading && <BoxLoader />}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </Container>
          </div>
        </React.Fragment>
      ))}
    </Root>
  );
}

const Root = tw.div`
h-auto 
flex 
md:flex-row 
flex-col 
justify-start 
items-start 
overflow-x-scroll 
overflow-y-hidden 
pb-4 
custom-scroll 
custom-display
gap-4
`;
const Container = tw.div`
md:min-w-[360px] 
min-w-fit
xl2:max-h-[400px] max-h-[500px]
border-[1px]
border-textColor 
rounded-2xl 
overflow-auto
overflow-y-scroll 
custom-scroll
pb-2
bg-boxBackground
`;
const component = `
w-full 
h-fit 
p-[2px] 
flex flex-wrap 
justify-center items-start
`;

export default TeeTimeView;
