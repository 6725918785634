import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import tw from "tailwind-styled-components";
import {
  RoundHole,
  HeadingText,
  CustomButton,
  SecondaryText,
} from "@components";
import {
  updateDate,
  showLoder,
  activePlayer,
  networkError,
  apiCall,
  checkTees,
  teeTimeApiSlug,
  getReduxTeeTime,
  getCachedTeeTime,
  showCompleteLoder,
  getFacilitiesData,
  removeReduxTeeTime,
  getTeeTimeDataPaginate,
} from "@store";
import { CheckLanguage } from "@utils";
import DatePickers from "./widgets/datePicker";
import "./style.css";
import "../containerStyle.css";

function FilterBox() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const updatedDate = useSelector((state) => state.teeTime.date);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilitiesData = clubData?.facility;
  const apiTimer = useSelector((state) => state.setting.time);
  const playerTypeId = apiTimer?.data?.data;
  const cachedTime = apiTimer?.data?.data?.cachingTime;
  const { cachedTeeTime, reduxTeeTime, isNetworksError } = useSelector(
    (state) => state.teeTime
  );
  const isApiCall = useSelector((state) => state.teeTime.apiCall);
  const activePlayers = useSelector((state) => state.courses.activePlayer);
  const isLoading = useSelector((state) => state.teeTime.showLoder);
  const isCompleteLoading = useSelector(
    (state) => state.teeTime.showCompleteLoder
  );
  const teeTimeApiSlugdata = useSelector(
    (state) => state.teeTime.teeTimeApiSlug
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      players: 1,
      hole: 18,
      date: moment(),
      pageNumber: 1,
      totalPage: 2,
      localTeeTime: [],
      lastCachedHours: 0,
      lastCachedMinutes: cachedTime || 60,
      filterCachedData: [],
      isPaginationApiCall: false,
    }
  );

  useEffect(() => {
    if (updatedDate) {
      setState({
        date: updatedDate,
        isPaginationApiCall: false,
      });
    }
  }, [updatedDate]);

  let apiData = {
    holes: state.hole,
    players: 1,
    bookingDate: state.date,
    facilitiesId: facilitiesData?.id,
    pageNumber: state.pageNumber,
    playerTypeId: playerTypeId?.casualPlayerTypeId,
    type: "individual",
  };

  useEffect(() => {
    let filterDate = cachedTeeTime?.filter((e) => {
      return e?.date === moment(updatedDate).format("DDMMYYYY");
    });

    if (filterDate && filterDate[0]?.teeTime?.length) {
      setState({ filterCachedData: filterDate[0]?.teeTime });
    }

    if (filterDate?.length) {
      const now = moment().local();
      const end = moment(filterDate[0]?.data?.lastCacheAt).local();
      const duration = moment.duration(now.diff(end));
      const hours = duration.hours();
      const minutes = duration.minutes();

      setState({ lastCachedHours: hours, lastCachedMinutes: minutes });
    } else {
      setState({ lastCachedHours: 0, lastCachedMinutes: 60 });
    }
  }, [cachedTeeTime, updatedDate, state.localTeeTime]);

  useEffect(() => {
    if (
      state.lastCachedHours >= 0 &&
      (state.lastCachedMinutes >= 60 || state.lastCachedMinutes === 0)
    ) {
      if (facilitiesData?.id && playerTypeId) {
        let apiData2 = { ...apiData };
        apiData2.bookingDate = updatedDate;
        dispatch(checkTees(apiData2)).then(async (re) => {
          if (re?.payload?.data && re?.payload?.status === 200) {
            let data = await re?.payload?.data?.data;
            let date = moment(apiData2.bookingDate).format("DDMMYYYY");
            if (cachedTeeTime?.find((e) => e?.date === date)) {
            } else {
              dispatch(
                getCachedTeeTime(
                  cachedTeeTime?.concat({
                    date: date,
                    data: data,
                    teeTime: data?.teeTimes,
                  })
                )
              );
            }

            if (data?.teeTimes?.length) {
              await dispatch(showLoder(false));
              await dispatch(showCompleteLoder(false));
              await dispatch(apiCall(false));
              await dispatch(getReduxTeeTime(data?.teeTimes));
              setState({ isPaginationApiCall: false });
            } else if (data?.isCached) {
              await dispatch(showLoder(false));
              await dispatch(showCompleteLoder(false));
              await dispatch(apiCall(false));
              setState({ isPaginationApiCall: false });
            } else {
              dispatch(showLoder(true));
              dispatch(showCompleteLoder(true));
              setState({ isPaginationApiCall: true });
            }
          }
        });
      }
    } else {
      dispatch(getReduxTeeTime(state.filterCachedData));
      dispatch(showLoder(false));
      dispatch(showCompleteLoder(false));
      dispatch(apiCall(false));
      setState({ isPaginationApiCall: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, facilitiesData, playerTypeId]);

  useEffect(() => {
    if (reduxTeeTime?.length && !isCompleteLoading) {
    } else {
      if (
        playerTypeId &&
        facilitiesData?.id &&
        state.pageNumber <= state.totalPage &&
        state.isPaginationApiCall
      ) {
        setTimeout(() => {
          dispatch(getTeeTimeDataPaginate(apiData)).then(async (res) => {
            const data = await res;
            if (data?.payload) {
              setState({ pageNumber: state.pageNumber + 1 });
            }
            if (data?.payload?.data?.data?.pagination) {
              setState({
                totalPage: data?.payload?.data?.data?.pagination?.total,
              });
            }

            if (data?.payload?.data?.data?.teeTimes?.length) {
              let currentTimeFilter = _.filter(
                data?.payload?.data?.data?.teeTimes,
                function (e) {
                  if (moment(e?.time).format("D") === moment().format("D")) {
                    return (
                      moment(e?.time).format("HHmm") > moment().format("HHmm")
                    );
                  } else {
                    return e;
                  }
                }
              );
              if (currentTimeFilter?.length) {
                setState({
                  localTeeTime: state.localTeeTime?.concat(
                    data?.payload?.data?.data?.teeTimes
                  ),
                });
                dispatch(showLoder(false));
              }
            }
          });
        }, 1400);
        dispatch(apiCall(false));
      }
      if (state.pageNumber > state.totalPage) {
        dispatch(showLoder(false));
        dispatch(showCompleteLoder(false));
        dispatch(apiCall(false));
        setState({ isPaginationApiCall: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    playerTypeId,
    facilitiesData,
    state.pageNumber,
    state.isPaginationApiCall,
  ]);

  useEffect(() => {
    if (state.localTeeTime?.length) {
      let arr = [...cachedTeeTime];
      for (let i = 0; i < cachedTeeTime?.length; i++) {
        if (moment(state.date).format("DDMMYYYY") === cachedTeeTime[i]?.date) {
          arr[i].teeTime = [...state.localTeeTime];
          arr[i].data.lastCacheAt = moment().local().toISOString();
        }
      }
      dispatch(getCachedTeeTime(arr));
      dispatch(getReduxTeeTime(state.localTeeTime));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.localTeeTime]);

  useEffect(() => {
    if (facilitiesData?.id) {
      dispatch(getFacilitiesData(facilitiesData?.id));
    }
  }, [dispatch, facilitiesData]);

  useEffect(() => {
    setState({ date: moment(teeTimeApiSlugdata?.apiData?.bookingDate) });
    if (
      teeTimeApiSlugdata?.apiData?.holes &&
      teeTimeApiSlugdata?.apiData?.players
    ) {
      setState({
        hole: teeTimeApiSlugdata?.apiData?.holes,
        players: teeTimeApiSlugdata?.apiData?.players,
      });
    }
  }, [teeTimeApiSlugdata]);

  useEffect(() => {
    if (isApiCall) {
      if (
        state.lastCachedHours >= 0 &&
        (state.lastCachedMinutes >= 60 || state.lastCachedMinutes === 0)
      ) {
        setState({
          pageNumber: 1,
          totalPage: 2,
        });
        dispatch(showLoder(true));
        dispatch(showCompleteLoder(true));
        dispatch(activePlayer(state.players));
        dispatch(networkError(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isApiCall]);

  useEffect(() => {
    if (isApiCall && state.date === updatedDate) {
      let filterDate = cachedTeeTime?.filter((e) => {
        return e?.date === moment(updatedDate).format("DDMMYYYY");
      });
      if (filterDate && filterDate[0]?.teeTime?.length) {
        setState({ filterCachedData: filterDate[0]?.teeTime });
        setState({ isPaginationApiCall: false });
        dispatch(showLoder(false));
        dispatch(showCompleteLoder(false));
        dispatch(apiCall(false));
        dispatch(getReduxTeeTime(filterDate[0]?.teeTime));
      } else {
        if (
          state.lastCachedHours >= 0 &&
          (state.lastCachedMinutes >= 60 || state.lastCachedMinutes === 0)
        ) {
          dispatch(checkTees(apiData)).then(async (re) => {
            if (re?.payload?.data && re?.payload?.status === 200) {
              let data = await re?.payload?.data?.data;
              let date = moment(apiData.bookingDate).format("DDMMYYYY");
              if (cachedTeeTime?.find((e) => e?.date === date)) {
              } else {
                dispatch(
                  getCachedTeeTime(
                    cachedTeeTime?.concat({
                      date: date,
                      data: data,
                      teeTime: data?.teeTimes,
                    })
                  )
                );
              }

              if (data?.teeTimes?.length) {
                setState({ isPaginationApiCall: false });
                await dispatch(showLoder(false));
                await dispatch(showCompleteLoder(false));
                await dispatch(apiCall(false));
                await dispatch(getReduxTeeTime(data?.teeTimes));
              } else if (data?.isCached) {
                setState({ isPaginationApiCall: false });
                await dispatch(showLoder(false));
                await dispatch(showCompleteLoder(false));
                await dispatch(apiCall(false));
              } else {
                dispatch(showLoder(true));
                dispatch(showCompleteLoder(true));
                setState({ isPaginationApiCall: true });
              }
            }
          });
        } else {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isApiCall,
    state.date,
    updatedDate,
    state.filterCachedData,
    state.lastCachedMinutes,
  ]);

  const changeDate = (e) => {
    if (!isCompleteLoading || !isLoading) {
      setState({ date: e, pageNumber: 1, totalPage: 2 });
    }
  };
  const changeHole = (e) => {
    if (!isCompleteLoading || !isLoading) {
      setState({
        hole: e,
      });
    }
  };
  const changePlayer = (e) => {
    if (!isCompleteLoading || !isLoading) {
      setState({
        players: e,
      });
      dispatch(activePlayer(e));
    }
  };

  useEffect(() => {
    if (activePlayers) {
      setState({
        players: activePlayers,
      });
    }
  }, [activePlayers]);

  useEffect(() => {
    if (updatedDate) {
      setState({
        date: updatedDate,
        isPaginationApiCall: false,
      });
    }
  }, [updatedDate]);

  const submit = () => {
    dispatch(removeReduxTeeTime());
    dispatch(updateDate(state.date));
    setState({
      pageNumber: 1,
      totalPage: 2,
      localTeeTime: [],
    });
    dispatch(showCompleteLoder(true));
    dispatch(showLoder(true));
    dispatch(teeTimeApiSlug({ apiData }));
    dispatch(networkError(false));
    dispatch(apiCall(true));
  };

  const PlayerHoleView = (props) => {
    return (
      <RoundHole
        onClick={() => changePlayer(props.value)}
        btnClass={
          state.players === props.value
            ? "bg-textColor hover:cursor-default"
            : `bg-holeBG border-[0.5px] border-textColor ${
                isCompleteLoading || isLoading
                  ? "hover:cursor-wait"
                  : "hover:cursor-pointer hover:opacity-70"
              }`
        }
        active={state.players === props.value ? true : false}
      >
        {props.value}
      </RoundHole>
    );
  };

  const HeadingView = () => {
    let dateLanguage = CheckLanguage() || "en";
    return (
      <div className=" hover:cursor-default">
        <HeadingText>
          {state.date
            ? moment(state.date).locale(dateLanguage).format("ddd, D MMM")
            : moment().locale(dateLanguage).format("ddd, D MMM")}
        </HeadingText>
      </div>
    );
  };

  return (
    <Container>
      <div className={DateView}>
        <SecondaryText>{t("date")}</SecondaryText>
        <HeadingView />
        <div className="h-auto w-auto">
          <DatePickers
            newDate={(e) => changeDate(e)}
            value={state.date}
            disabled={isCompleteLoading || isLoading}
          />
        </div>
      </div>
      <SecondaryText>{t("holes")}</SecondaryText>
      <div className={HoleView}>
        <RoundHole
          onClick={() => changeHole(9)}
          btnClass={
            state.hole === 9
              ? "bg-textColor hover:cursor-default"
              : `bg-holeBG border-[0.5px] border-textColor ${
                  isCompleteLoading || isLoading
                    ? "hover:cursor-wait"
                    : "hover:cursor-pointer hover:opacity-70"
                }`
          }
          active={state.hole === 9 ? true : false}
        >
          9
        </RoundHole>
        <RoundHole
          onClick={() => changeHole(18)}
          btnClass={
            state.hole === 18
              ? "bg-textColor hover:cursor-default"
              : `bg-holeBG border-[0.5px] border-textColor ${
                  isCompleteLoading || isLoading
                    ? "hover:cursor-wait"
                    : "hover:cursor-pointer hover:opacity-70"
                }`
          }
          active={state.hole === 18 ? true : false}
        >
          18
        </RoundHole>
      </div>
      <SecondaryText>{t("players")}</SecondaryText>
      <div className={HoleView2}>
        <PlayerHoleView value={1} />
        <PlayerHoleView value={2} />
        <PlayerHoleView value={3} />
        <PlayerHoleView value={4} />
      </div>
      <CustomButton
        onClick={submit}
        btnLoading={!isNetworksError && (isCompleteLoading || isLoading)}
        value={t("refreshTeeTime")}
        disabled={!isNetworksError && (isCompleteLoading || isLoading)}
      />
    </Container>
  );
}

const Container = tw.div`
p-4
w-[100%]
h-auto
bg-boxBackground
mt-[6px]
rounded-2xl
shadow-xl
drop-shadow-xl
`;
const DateView = `
flex
flex-row
items-center
justify-between
mb-[5px]
`;
const HoleView = `
mt-[5px]
mb-[10px]
flex
flex-row
h-[40px]
w-[42%]
items-center
justify-between
gap-x-2
`;
const HoleView2 = `
mt-[5px]
mb-[20px]
flex
flex-row
h-[40px]
items-center
justify-between
`;

export default FilterBox;
