import React, { useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import Skeleton from "@mui/material/Skeleton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IconButton from "@mui/material/IconButton";
import { FilterBox, ClubDetails } from "@containers";
import { WeatherView, PrimaryText } from "@components";
import { CheckLanguage, filterTeeTimeData } from "@utils";
import {
  apiCall,
  showLoder,
  updateDate,
  activePlayer,
  showCompleteLoder,
  removeReduxTeeTime,
  getSelectedPlayerData,
} from "@store";
import TeeTimeView from "./widgets/teeTimeView";
import CartModal from "./widgets/addToCartModal";
import { CachedTimeView } from "./widgets/cachedTimeView";
import "../containerStyle.css";

function TeeTimeBooking() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const updatedDate = useSelector((state) => state.teeTime.date);
  const activePlayers = useSelector((state) => state.courses.activePlayer);
  const isLoading = useSelector((state) => state.teeTime.showLoder);
  const { isNetworksError, reduxTeeTime, cachedTeeTime } = useSelector(
    (state) => state.teeTime
  );
  const teeTimeApiSlugdata = useSelector(
    (state) => state.teeTime.teeTimeApiSlug
  );
  const isCompleteLoading = useSelector(
    (state) => state.teeTime.showCompleteLoder
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      activeDate: moment(),
      loading: false,
      cartModal: false,
      lastCahcedDate: "",
      lastCachedHours: 0,
      lastCachedMinutes: 15,
      upComming_Date: [0, 1, 2, 3],
    }
  );

  useEffect(() => {
    setState({ activeDate: moment(teeTimeApiSlugdata?.apiData?.bookingDate) });
  }, [teeTimeApiSlugdata]);

  useEffect(() => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    if (localdata?.length && !addToCartData?.length) {
      dispatch(
        getSelectedPlayerData({ localdata: localdata, localSave: true })
      );
    }
  }, [dispatch, addToCartData]);

  useEffect(() => {
    setState({ activeDate: moment(teeTimeApiSlugdata?.apiData?.bookingDate) });
  }, [teeTimeApiSlugdata]);

  useEffect(() => {
    if (updatedDate) {
      setState({
        activeDate: updatedDate,
      });
    }
  }, [updatedDate]);

  useEffect(() => {
    const dateFilter = reduxTeeTime?.filter((res) => {
      return moment(res?.time).isSame(state.activeDate, "day");
    });
    const currentTimeFilter = _.filter(dateFilter, function (e) {
      if (moment(e?.time).format("D") === moment().format("D")) {
        return moment(e?.time).format("HHmm") > moment().format("HHmm");
      } else {
        return e;
      }
    });
    const playerFilter = currentTimeFilter?.filter((res) => {
      return res?.players >= activePlayers;
    });

    const filterTeesData = filterTeeTimeData(playerFilter);
    if (filterTeesData?.length) {
      setState({ data: filterTeesData });
    } else {
      setState({ data: [] });
    }
  }, [reduxTeeTime, state.activeDate, activePlayers]);

  useEffect(() => {
    let filterDate = cachedTeeTime?.filter((e) => {
      return e?.date === moment(state.activeDate).format("DDMMYYYY");
    });

    if (filterDate?.length) {
      const now = moment();
      const end = moment(filterDate[0]?.data?.lastCacheAt).local();
      const duration = moment.duration(now.diff(end));
      const hours = duration.hours();
      const minutes = duration.minutes();

      setState({
        lastCachedHours: hours,
        lastCachedMinutes: minutes,
        lastCahcedDate: filterDate[0]?.data?.lastCacheAt,
      });
    } else {
      setState({
        lastCachedHours: 0,
        lastCachedMinutes: 15,
        lastCahcedDate: moment(),
      });
    }
  }, [cachedTeeTime, state.activeDate, reduxTeeTime]);

  const closeModal = () => {
    setState({ cartModal: false });
  };

  const ShowMessage = (props) => {
    return (
      <div className="w-full h-full flex justify-center items-start mt-[40px] mb-[40px] py-[15px]">
        <PrimaryText
          className={"center text-2xl text-textColor animate-pulse text-start "}
        >
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const changeDate = (newDate) => {
    if (
      moment(state.activeDate).format("DD-MM-YYYY") ===
      moment(newDate).format("DD-MM-YYYY")
    ) {
    } else {
      dispatch(removeReduxTeeTime());
      dispatch(updateDate(newDate));
      dispatch(showLoder(true));
      dispatch(showCompleteLoder(true));
      dispatch(activePlayer(teeTimeApiSlugdata?.apiData?.players || 1));
      setState({ activeDate: moment(newDate), loading: true });
      setTimeout(() => {
        setState({ loading: false });
        dispatch(apiCall(true));
      }, 300);
    }
  };

  const DateView = (props) => {
    let dateLanguage = CheckLanguage() || "en";
    const dayName = moment(props?.title)
      .locale(dateLanguage)
      .format("ddd, D MMM");
    return (
      <DateBtn
        onClick={() => changeDate(props?.title)}
        disabled={isLoading || isCompleteLoading}
        className={`${
          moment(state.activeDate).format("DD-MM-YYYY") ===
          moment(props?.title).format("DD-MM-YYYY")
            ? "bg-primary hover:cursor-default"
            : `bg-black02 ${
                isLoading || isCompleteLoading
                  ? "hover:cursor-wait"
                  : "hover:opacity-70"
              }`
        }`}
      >
        <p className={`text-sm font-[Cambay] text-center text-white255`}>
          {dayName}
        </p>
      </DateBtn>
    );
  };

  const CartView = () => {
    return (
      <div
        className={`${
          addToCartData?.length < 1 ? "cursor-not-allowed" : "cursor-default"
        }`}
      >
        <div
          className={`w-fit h-[40px] flex flex-row items-center justify-start rounded-full gap-x-2 bg-black03 ${
            addToCartData?.length > 0 ? "hover:cursor-pointer" : ""
          } `}
          onClick={() =>
            setState({ cartModal: addToCartData?.length > 0 ? true : false })
          }
        >
          <div>
            <IconButton
              disabled={addToCartData?.length > 0 ? false : true}
              sx={{ bgcolor: "#ffffff36" }}
            >
              {addToCartData?.length > 0 ? (
                <ShoppingCartIcon fontSize="medium" sx={{ color: "white" }} />
              ) : (
                <ShoppingCartIcon fontSize="medium" sx={{ color: "#858585" }} />
              )}
            </IconButton>
          </div>
          {addToCartData?.length > 0 && (
            <div className="h-full w-[80px] flex flex-col items-start justify-center overflow-hidden">
              <PrimaryText className="leading-4 text-white255 opacity-80 text-[13px]">
                {addToCartData?.length + " " + t("select")}
              </PrimaryText>
              <PrimaryText className="leading-4 text-white255 font-medium text-[17px]">
                {getSymbolFromCurrency(
                  addToCartData[0]?.price?.price?.currency
                ) +
                  " " +
                  totalPrice}
              </PrimaryText>
            </div>
          )}
        </div>
        <CartModal openModal={state.cartModal} closeModal={closeModal} />
      </div>
    );
  };

  const SkeletonLoader = () => {
    const TxtLoader = () => {
      return (
        <div className="w-[128px] h-[25px]">
          <Skeleton
            variant="text"
            sx={{ bgcolor: "#00000096" }}
            width={128}
            height={25}
          />
        </div>
      );
    };
    const BoxLoader = () => {
      return (
        <div className="w-[90px] h-[67px] rounded-lg">
          <Skeleton
            variant="rounded"
            sx={{ bgcolor: "#ffffff5c", borderRadius: "10px" }}
            animation="wave"
            width={90}
            height={67}
          />
        </div>
      );
    };

    const boxLoaderMap = Array(12).fill("");

    return (
      <LoaderView>
        <AnimationTeeTime>
          <div className="w-full h-full flex flex-col items-center gap-2">
            <TxtLoader />
            <AnimatedBox>
              {boxLoaderMap?.map((item, index) => (
                <BoxLoader key={index} />
              ))}
            </AnimatedBox>
          </div>

          <div className="w-full h-full xl3:flex hidden flex-col items-center gap-2">
            <TxtLoader />
            <AnimatedBox>
              {boxLoaderMap?.map((item, index) => (
                <BoxLoader key={index} />
              ))}
            </AnimatedBox>
          </div>
        </AnimationTeeTime>
      </LoaderView>
    );
  };

  const prices = addToCartData.length
    ? addToCartData.map((a) => a?.price?.publicRate?.amount)
    : "";
  const totalPrice = addToCartData.length
    ? prices.reduce((acc, curr) => acc + curr)
    : "";

  return (
    <Root>
      <TEES>
        <Filters>
          <FilterBox />
          <WeatherView />
        </Filters>
        <div
          className={`${teeTimeBookingView}${
            !isLoading && !state.data?.length
              ? `xl2:w-full md3:w-[54%] w-full xl2:px-4 px-0`
              : `md:px-4 px-0 xl2:w-[54%] w-full`
          }`}
        >
          {
            <Component>
              <div className={dateView}>
                {state.upComming_Date.map((e, i) => (
                  <DateView
                    key={`date${i}`}
                    title={moment(teeTimeApiSlugdata?.apiData?.bookingDate).add(
                      e,
                      "day"
                    )}
                  />
                ))}
              </div>
              <div className="h-auto w-auto flex md:flex-row flex-col gap-2">
                {!isLoading && !isCompleteLoading && (
                  <CachedTimeView
                    lastCachedHours={state.lastCachedHours}
                    lastCachedMinutes={state.lastCachedMinutes}
                    lastCahcedDate={state.lastCahcedDate || moment().local()}
                  />
                )}
                {CartView()}
              </div>
            </Component>
          }
          {isNetworksError ? (
            <ShowMessage title={"Failed To Load TeeTime Data !"} />
          ) : isLoading || state.loading ? (
            <SkeletonLoader />
          ) : state.data?.length ? (
            <Container>
              <TeeTimeView data={state.data} />
            </Container>
          ) : (
            <ShowMessage title={t("noTeeTimeMessage")} />
          )}
        </div>

        <Clubs>
          <div className={club_View}>
            <ClubDetails />
          </div>
        </Clubs>
      </TEES>
    </Root>
  );
}

const Root = tw.div`
w-full 
h-auto
px-4
pt-4
flex
items-between
justify-center
lg2:flex-row
flex-col
`;
const TEES = tw.div`
h-auto
w-full
flex
xl2:flex-row
flex-col
xl2:items-start
items-center
justify-between
`;
const Filters = tw.div`
w-full
max-w-[355px] 
h-auto 
md:flex
flex-col 
items-center
`;
const teeTimeBookingView = `
h-auto 
md2:my-0 
my:-[40px]
justify-center
items-center
xl2:pt-[6px]
pt-6
`;
const Clubs = tw.div`
w-full
max-w-[355px]
h-auto
md:flex
flex-row
items-between
justify-between
rounded-lg
`;

const club_View = `
w-full 
h-full 
flex 
xl2:justify-end justify-center 
rounded-lg
`;

const Container = tw.div`
w-auto
h-auto
mx-[2px]
px-[2px]
mt-[3px]
mb-[33px]
`;

const Component = tw.div`
w-full 
h-auto 
flex flex-row 
items-start justify-between 
gap-[4px] 
mx-[2px] 
px-[2px] 
mt[3px]
`;
const dateView = `
w-full 
h-auto 
flex 
flex-wrap 
itmes-center
gap-2
`;
const DateBtn = tw.button`
w-[90px] 
h-auto 
rounded-2xl 
flex 
items-center 
justify-center 
pt-[6px]
pb-[1px]
`;

const LoaderView = tw.div`
w-full 
lg2:h-[470px] h-fit 
flex 
flex-col 
justify-center 
items-center 
gap-2 
rounded-lg 
p-2 
overflow-hidden
mb-8
`;
const AnimationTeeTime = tw.div`
flex 
flex-row 
items-center 
justify-around 
w-full 
h-full 
rounded-lg 
gap-2
`;
const AnimatedBox = tw.div`
w-full 
h-full 
flex 
flex-wrap 
items-center 
justify-center 
gap-2 
border-[2px]
border-textColor 
rounded-2xl 
py-2
`;

export default TeeTimeBooking;
