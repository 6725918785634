import React from "react";

const IsValidatePassword = (password) => {
  const passwordRegex =
    // /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#^])[A-Za-z\d@$!%*?&.#^]{8,}$/;
    /^(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[\W_]){2,})(?=(.*[a-z]){2,}).{8,}$/;
  return passwordRegex.test(password);
};

export default IsValidatePassword;
